<template>
	<nav class="sidebar bg-primary bg-gradient h-100">
		<div class="brand p-4">
			<h4 class="font-weight-bold">
				<img
					src="img/logo/logoB.png"
					alt="株式会社カネヨシ"
					class="img-fluid"
				/>
			</h4>
		</div>
		<ul class="nav flex-column m-o p-3">
			<li class="border-bottom" @click="SideMenu" v-scroll-to="'#top'">
				トップページ
			</li>
			<li class="border-bottom" @click="SideMenu" v-scroll-to="'#origin'">
				カネヨシ原点
			</li>
			<li class="border-bottom" @click="SideMenu" v-scroll-to="'#beef'">
				自慢の黒毛和牛
			</li>
			<li class="border-bottom" @click="SideMenu" v-scroll-to="'#ramen'">
				麻辣湯へのこだわり
			</li>
			<!-- <li
				class="border-bottom"
				@click="SideMenu"
				v-scroll-to="'#kannohana'"
			>
				韓の花
			</li> -->
			<li class="border-bottom" @click="SideMenu" v-scroll-to="'#shops'">
				店舗紹介
			</li>

			<li
				class="border-bottom"
				@click="SideMenu"
				v-scroll-to="'#company'"
			>
				会社概要
			</li>
			<li
				class="border-bottom"
				@click="SideMenu"
				v-scroll-to="'#history'"
			>
				沿革
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	methods: {
		SideMenu() {
			this.$emit("menu-click", false);
		},
	},
};
</script>

<style scoped>
.sidebar {
	position: fixed;
	max-width: 250px;
	min-width: 250px;
}
.sidebar .brand {
	background-color: #0d6efdc9;
	color: white;
}
ul li {
	color: white;
	font-family: "Kosugi Maru", sans-serif;
	font-weight: bold;
	font-size: 18px;
	padding: 10px;
}
ul li:hover {
	cursor: pointer;
	background-color: #b4b4fa;
}
</style>
