<template>
	<section id="history">
		<TitleGroup MainTitle="沿革" />
		<div class="timeline">
			<ul class="timeline-list">
				<li
					class="timeline-list-item"
					v-for="history in ViewHistory"
					:key="history.Times"
				>
					<div class="date">{{ history.Times }}</div>
					<div class="content">{{ history.Content }}</div>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
import TitleGroup from "../Molecules/TitleGroups.vue";

export default {
	computed: {
		ViewHistory() {
			return this.$store.getters["history/ViewHistory"];
		},
	},
	components: {
		TitleGroup,
	},
};
</script>

<style scoped>
.timeline {
	margin: 28px auto;
	border: 1px solid #eeeeee;
}

.timeline-list {
	padding: 40px 0;
}

.timeline-list-item {
	display: flex;
	line-height: 1.5;
	font-size: 16px;
}

.timeline-list-item .date {
	width: 20%;
	padding: 0 0 0 20px;
	color: #888888;
	font-weight: bold;
}

.timeline-list-item .content {
	position: relative;
	width: 80%;
	padding: 0 20px 60px 30px;
	border-left: 1px solid #aaaaaa;
}

.timeline-list-item .content::before {
	content: "";
	position: absolute;
	top: 0;
	left: -10px;
	width: 20px;
	height: 20px;
	background-color: #00c2bc;
	border-radius: 10px;
}
</style>
